.card {
    border: 1px solid #ddd;
    border-radius: 10px;
    overflow: hidden;
    width: 250px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
  }
  
  .card:hover {
    transform: scale(1.05);
  }
  
  .card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .card-content {
    padding: 20px;
  }
  
  .card-title {
    font-size: 1.5em;
    margin: 0;
  }
  
  .card-description {
    font-size: 1em;
    margin: 10px 0;
  }
  
  .card-button {
    background-color: #e74c3c;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-transform: uppercase;
    font-size: 0.9em;
  }
  
  .card-button:hover {
    background-color: #c0392b;
  }
  