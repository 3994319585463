.carousal-container{
    margin-top: 20px;
}
.carousel .slide .legend{
    display: none;
}
.carousel .carousel-status {
    display: none;
}
.carousalContainer{
    width: 100%;
}
.heading-container{
    top: 15%;
    left: 3%;
    position: absolute;
}
.h1Heading{
    width: 65%;
    color: white;
    font-size: 60px;
    font-weight: 600;
    text-align: initial;
}
.h3Heading {
    font-size: 25px;
    color: white;
    text-align: justify;
}
.main-container {
    position: relative;
    height: 500px; /* Set your desired height */
    background-image: linear-gradient(to right, #e52c21 , #2862a3);
  }
  .apply-now {
    display: flex;
    align-items: center;
    background: transparent;    border: 2px solid white;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: white;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
  }
  
  .apply-now:hover {
    background: transparent;
  }
  
  .apply-text {
    margin-right: 10px;
  }
  
  .arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border-radius: 50%;
    color: #c51216;
    font-size: 18px;
    font-weight: bold;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the background image covers the container */
    z-index: 1;
  }
  
  .overlay-image {
    position: absolute;
    /* top: 50px; */
    right: 20px;
    bottom: -21px;
    /* left: 50px; */
    height: 556px;
    z-index: 2;
  }
  @media only screen and (max-width: 768px) {
    .heading-container{
      top: 1%;
      left: 3%;
  }
  .overlay-image{
    height: 200px;
    width: 200px;
  }
  .h1Heading{
    font-size: 37px;
    width: auto;
  }
  .h3Heading{
    font-size: 20px;
  }
  .main-container{
    height: 400px;
  }
  .carousal-container{
    margin-top: 0px;
}
  }
  