/* src/ProductHighlight.css */
.product-highlight-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 50px;
    background-color: #f8f9fa;
    color: #003c6b;
  }
  
  .product-image {
    padding-right: 20px;
  }
  
  .product-image img {
    height: 500px;
    width: 500px;
    border-radius: 20px;
  }
  
  .product-text {
    flex: 1;
    text-align: left;
    padding-right: 7%;
  }
  
  .product-text h1 {
    font-size: 37px;
    margin-bottom: 20px;
  }
  
  .product-text p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 30px;
  }
  
  .learn-more-button {
    padding: 10px 20px;
    background-color: #ff5e99;
    color: #fff;
    height: 48px;
    font-size: 23px;
    width: 20%;
    border: none;
    border-radius: 11px;
    cursor: pointer;
  }  
  @media only screen and (max-width: 768px) {
    .product-highlight-section {
      display: block;
      padding: 2%;    }
    .product-image img {
      height: 350px;
      width: 350px;
    }
    .product-text h1{
      text-align: center;
    }
    
  }