.cardcarousel {
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, #e52c21 , #2862a3);
    color: #fff;
    height: 500px;
    position: relative;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  
  .carousel-cards-container {
    display: flex;
    transition: transform 0.5s ease-in-out;
    justify-content: space-between;
    gap: 18px;

  }
  
  .carousel-card {
    flex: 0 0 20%;
    box-sizing: border-box;
    border: 2px solid;    padding: 10px;
    height: 300px;
    width: 300px;
    border-radius: 20px;
  }
  
  .carousel-card div {
    width: 100%;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    background-color: #fff;
    text-align: center;
  }
  
  .carousel-button {
    background-color: #ffffff00;
    /* border: 1px solid black; */
    border-radius: 50%;
    cursor: pointer;
    padding: 10px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .carousel-button-left {
    left: 10px;
  }
  
  .carousel-button-right {
    right: 10px;
  }
  
  .carousel-button:hover {
    background-color: #ffffff2e;
  }
  