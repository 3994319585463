.App {
  font-family: sans-serif;
  text-align: center;
}
body {
  margin: 0px;
}
.carousel .slider-wrapper {
  overflow: hidden;
  margin: auto;
  margin-top: 4px;
  width: 100%;
  transition: height .15s ease-in;
}
