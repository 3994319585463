.footer-container {
    display: flex;
    background-color: white;
    background-position: center;
    background-size: cover;
    color: #003c6b;
    padding: 40px 20px;
    text-align: center;
    justify-content: space-around;
    height: 500px;
  }
  
  .footer-title {
    font-size: 45px;
    margin-bottom: 87px;
    text-align: initial;
    }
  
  .contact-details {
    margin: 5px 0;
  }
 
  .footer-container .contact-info .bold{
    margin-top: 45px;
    font-weight: 600;
  }
  .social-icons {
    display: flex;
    margin-top: 20px;
  }
  .footer-container .contact-info p {
    margin: 5px 0;
    font-size: 1.2em;
    color: #003c6b;}
  .icon {
    color: #003c6b;
    margin: 0 10px;
    font-size: 1.5em;
    text-decoration: none;
    transition: color 0.3s;
  }
  
  .icon:hover {
    color: #003c6b;
  }
  
  .google-map {
    width: 49%;
    height: 328px;
}
  .contact-info {
    margin-bottom: 30px;
    text-align: initial;
}
@media only screen and (max-width: 768px) {
  .footer-container {
    display: block;
    height: auto;
  }
  .google-map {
    width: 86%;
    height: 57%;
}
.footer-title {
  margin-bottom: 40px;
  text-align: center;
}
.contact-info{
  text-align: center;
}
.social-icons{
  justify-content: center;
}
}