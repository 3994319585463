.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px 80px;
    box-shadow: 0px 8px 20px rgba(26, 57, 96, 0.05);
    position: sticky;
  }

  .left-side {
    display: flex;
    align-items: center;
  }
  
  .logo {
    height: 50px;
  }
  
  .right-side {
    display: flex;
    align-items: center;
  }
  
  .buttononHeader {
    margin-left: 20px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 2px solid #d9d9d9;
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    color: #7d7d7d;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    box-shadow: 0 0 8px rgba(0, 128, 0, 0.3), 0 0 8px rgba(255, 165, 0, 0.3);
    transition: all 0.3s ease;
  }
  .buttononHeader:hover {
    background-image: linear-gradient(to right, #e52c21 , #2862a3);
        color: white;
  }

  @media only screen and (max-width: 1080px) {
    .header {
        padding: 10px 10px;
      }
      .logo {
        height: 25px;
      }
      
  }
  @media only screen and (max-width: 768px) {
    .buttononHeader{
      font-size: 10px;
      padding: 10px 10px;

    }
  }