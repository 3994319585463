.calculator-container {
  max-width: 500px;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #d9d9d9;
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 0 8px rgba(0, 128, 0, 0.3), 0 0 8px rgba(255, 165, 0, 0.3);
}

.emih1 {
  text-align: center;
  color: #003c6b;
}

form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #003c6b;
}

input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #003c6b;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

button:hover {
  background-color: #003c6b;
}

.emis-list {
  margin-top: 20px;
}

.emis-list h2 {
  text-align: center;
  color: #003c6b;
}

.emis-list ul {
  list-style-type: none;
  padding: 0;
}

.emis-list li {
  background-color: #003c6b0d;
  padding: 10px;
  border: 1px solid #003c6b;
  border-radius: 5px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #003c6b;
}

.calculator-container {
  font-family: Arial, sans-serif;
  max-width: 600px;
  margin:20px auto;
  padding: 20px;
}

.tenure-type {
  display: flex;
  gap: 20px;
}

.tenure-type label {
  display: flex;
  align-items: center;
}

.emi-details, .emis-list {
  margin-top: 20px;
}

.emis-list ul {
  list-style-type: none;
  padding: 0;
}

.emis-list li {
  margin-bottom: 5px;
}

.calculator-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin:20px auto;
  padding: 20px;
}

.emih1 {
  text-align: center;
  color: #0044cc;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

input {
  margin-top: 5px;
  padding: 8px;
  font-size: 16px;
}

.tenure-type {
  display: flex;
  gap: 20px;
  margin: auto;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #0044cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #003399;
}

.emi-details, .emis-list {
  margin-top: 20px;
}

.emi-details p {
  font-size: 18px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #0044cc;
  color: white;
}

.calculator-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin:20px auto;
  padding: 20px;
}

.emih1 {
  text-align: center;
  color: #0044cc;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 10px;
}

input {
  margin-top: 5px;
  padding: 8px;
  font-size: 16px;
}

.tenure-type {
  display: flex;
  gap: 20px;
  margin: auto;
}

button {
  padding: 10px;
  font-size: 16px;
  background-color: #0044cc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #003399;
}

.emi-details, .emis-list {
  margin-top: 20px;
}

.emi-details p {
  font-size: 18px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

table, th, td {
  border: 1px solid #ddd;
}

th, td {
  padding: 10px;
  text-align: center;
}

th {
  background-color: #0044cc;
  color: white;
}


.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.page-info {
  margin: 0 10px;
}
