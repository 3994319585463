/* src/Services.css */

.services-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 50px 2%;
    gap: 20px;
    background-image: linear-gradient(to right, #e52c21 , #2862a3);
    color: #fff;
    height: 410px;
}

.text-content {
    max-width: 50%;
    text-align: initial;
}

.text-content h1 {
    font-size: 38px;
    margin-bottom: 20px;
}

.text-content p {
    font-size: 17px;
    line-height: 1.6;
}

.house-icon {
    width: 178px;
}

.services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    background-color: #1e6bb8;
    border-radius: 15px;
    padding: 40px;
}

.service-item {
    background-color: #ffffff;
    color: #1e6bb8;
    padding: 20px;
    text-align: center;
    border-radius: 10px;
    font-size: 18px;
    font-weight: bold;
}
@media only screen and (max-width: 768px) {
    .services-section {
      display: block;
      height: auto;
      padding: 1px 2%;
      padding-bottom: 18px;
    }
    .text-content {
        max-width: fit-content;
    }
    .text-content h1{
        text-align: center;
    }
  }
