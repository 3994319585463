.Productcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 50px 2%;
  gap: 20px;
  background-image: linear-gradient(to right, #e52c21 , #2862a3);
  color: #fff;
  height: 500px;
}

.content {
  color: #fff;
  text-align: center;
  margin-right: 50px;
}

.learn-more {
  background-color: #ff69b4;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.image {
  border-radius: 10px;
}

.image img {
  width: 500px;
  height: 500px;
  object-fit: cover;
}
@media only screen and (max-width: 768px) {
  .Productcontainer {
    display: block;
    height: auto;
    padding: 1px 2%;
    padding-bottom: 4%;
  }
  .image img {
    width: 350px;
    height: 350px;
  }
}