.reviews-carousel {
    position: relative;
  
  }
  .slick-arrow {
    display: none !important;
}
  
  .review-slide {
    /* background: rgba(0, 0, 0, 0.5); */
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    text-align: center;
    margin: auto;
    width: 50%;
  }
  
  .review-slide h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .review-slide p {
    font-size: 18px;
  }
  .carousel {
    justify-content: space-between;
    align-items: center;
    background-image: linear-gradient(to right, #e52c21 , #2862a3);
    color: #fff;
    position: relative;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    .review-slide {
       width: 100%;
    }
  }