.section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f9fbfde6;
    color: #003c6b;
  }
  
  .section-title {
    font-size: 38px;
    margin: 20px 0;
  }
  .top-image{
    margin-right: 32px;
  }
  .section-text {
    /* font-weight: 700; */
    font-size: 16px;
    text-align: initial;
    line-height: 32px;
    /* line-height: 31px; */
    margin: 20px 20px;
  }
  .section-text div {
    margin: 2% 0;
  }
  
  .image-container {
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    align-items: end;
    padding: 50px 2%;
    align-items: center;
  }
  
  .section-image {
    height: 500px;
    width: 500px;
    border-radius: 20%;
  }
  @media only screen and (max-width: 768px) {
    .image-container {
      display: contents;
    }
    .section-image {
      height: 350px;
      width: 350px;
    }
    .section-container{
      padding-top: 3%;
    } 
    .read-more-button {
      margin-bottom: 20px;
    }
  }
  