.contact-section {
    background-color: #7ac9fa;
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    color: #003c6b;
  }
  
  .contact-info {
    margin-bottom: 30px;
  }
  
  .contact-info h2 {
    font-size: 2.5em;
    margin-bottom: 20px;
  }
  
  .contact-info p {
    margin: 5px 0;
    font-size: 1.2em;
    color: #555;
  }
  
  .social-media-links {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .social-media-links a {
    margin: 0 15px;
    font-size: 1.8em;
    color: #555;
    text-decoration: none;
  }
  
  .social-media-links a:hover {
    color: #000;
  }
  
  .whatsapp-contact {
    display: flex;
    align-items: center;
    background-color: #25d366;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1.2em;
    width: 30%;
    gap: 10px;
  }
  
  .whatsapp-contact img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }
  .contact-container{
    display: flex;
    align-items: center;
    gap: 10%;
  }
  